<template>
    <div>
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <ul v-else class="frozen-ul" v-infinite-scroll="getData">
            <router-link v-for="(m, mi) in list" :to="'/frozendes?id='+m.id">
                <li class="list">
                    <div class="time-box">
                        <p class="time" v-if="m.start_time">开始返利：{{m.start_time}}</p>
                        <p class="time" v-else>下单：{{m.create_time}}</p>
                        <span class="time" v-if="m.fanli_day_rate">返利比例：{{m.fanli_day_rate}}</span>
                    </div>
                    <div class="li-box">
                        <img class="shop-img" v-if="m.goods" :src="rImg(m.goods.image)" :onerror="errImg" alt="">
                        <div class="content">
                            <div class="name ellipsis-1" v-if="m.goods"><span class="lab">返利{{m.fanli_rate}}%</span>{{m.goods.name}}</div>
                            <div class="price">可返<span class="price-val">{{m.fanli_count}}</span>积分
                                <span class="progress" v-if="m.fanli_day_rate_add">已加速返利{{m.fanli_day_rate_add}}</span>
                            </div>
                            <div class="cashback">
                                <span class="cashback-ok">已返{{m.fanlied_count}}积分</span>
                                <!-- <span class="cashback-no" v-if="m.fanlied_dec && Number(m.fanlied_dec)">待返{{m.fanlied_dec}}积分</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="bottom-box">
                        <p class="days"><span class="num">{{m.dec_day}}</span>天后完成返利</p>
                        <button class="btn">请朋友加速</button>
                    </div>
                    <!-- 下级返利 -->
                    <div class="order-box" v-if="m.type == 2 && m.orders">
                        <div class="user" v-if="m.orders.user">
                            <img class="tx" :src="rImg(m.orders.user.head_portrait)" :onerror="txImg" alt="">
                            <span class="name">{{m.orders.user.name}}</span>
                        </div>
                        <span class="total">订单总金额：<span class="num">￥{{m.orders.total}}</span></span>
                    </div>
                </li>
            </router-link>
        </ul>
    </div>
</template>
<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                list: [],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.$y_setWebToken();
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1);
        },
        methods: {
            // ...mapMutations([])
            async getData(init) {
                if (init) {
                    this.list = [];
                }
                let {
                    list
                } = await this.$y_list("api/mall.rebate/lists", this.list);
                this.list = list;
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .list {
        &:last-child {
            border-bottom: 1px solid #f3f3f3;
        }
    }

    .time-box {
        background: #f3f3f3;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .li-box {
        display: flex;
        padding: 15px 0;
        margin: 0 10px;

        .content {
            flex: 1;
        }
    }


    .frozen-ul {
        .shop-img {
            border-radius: 10px;
            width: 80px;
            height: 80px;
            margin-right: 10px;
        }
    }

    .lab {
        background: #fd5621;
        color: #fff;
        border-radius: 3px;
        padding: 2px 5px;
        font-size: 12px;
        margin-right: 7px;
    }

    .price {
        font-size: 12px;
        margin: 7px 0;
    }

    .price-val {
        font-size: 18px;
        color: orangered;
        margin: 0 3px;
    }

    .cashback {
        span {
            font-size: 12px;
            color: #aaa;
            margin-right: 10px;
        }

        .cashback-ok {
            color: orangered;
        }

    }

    .time {
        font-size: 12px;
        border-radius: 3px;
        color: #999;
    }

    .progress {
        color: orange;
        font-size: 12px;
        margin-left: 10px;
    }

    .btn {
        background: orangered;
        color: #fff;
        border-radius: 15px;
        font-size: 12px;
        padding: 7px 10px;
        float: right;
        outline: 0;
        border: 0;
    }

    .bottom-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 10px;

        .days {
            font-size: 12px;
            color: #aaa;
        }

        .days .num {
            color: orangered;
            margin-right: 3px;
        }
    }

    .frozen-un {
        color: #CD7DFE;
        background: #F9F1FE;
        padding: 5px 10px;
        border-radius: 15px;
    }

    .shop-state {
        color: orangered;
        background: #FFECE5;
        padding: 2px 5px;
        border-radius: 3px;
        font-size: 12px;
    }

    .order-box {
        background: #fafafa;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user {
            display: inline-flex;
            align-items: center;
        }

        .tx {
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }

        .total {
            font-size: 12px;
            color: #aaa;

            .num {
                color: orangered;
            }
        }
    }
</style>